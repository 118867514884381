<template>
  <!-- Messages Dropdown Menu -->
  <li class="nav-item dropdown">
    <a class="nav-link" data-toggle="dropdown" href="#">
      <i class="far fa-comments"></i>
      <span class="badge badge-danger navbar-badge">3</span>
    </a>
    <div class="dropdown-menu dropdown-menu-lg dropdown-menu-right">
      <CoverItem></CoverItem>
      <div class="dropdown-divider"></div>
      <CoverItem></CoverItem>
      <div class="dropdown-divider"></div>
      <CoverItem></CoverItem>
      <div class="dropdown-divider"></div>
      <Item></Item>
      <div class="dropdown-divider"></div>
      <a href="#" class="dropdown-item dropdown-footer">See All Messages</a>
    </div>
  </li>
</template>

<script>
import Item from "./Message-Item.vue"
import CoverItem from "./Message-Cover-Item.vue"
export default {
  components: {
    Item,
    CoverItem
  },
}
</script>

<style>

</style>